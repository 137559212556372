.fancybox__container { --fancybox-bg: #{rgba(color('neutral', 900), .96)}; }
.fancybox__nav { --fancybox-color: #FFF; }
.f-button[disabled] { color: rgba(255, 255, 255, .32); }

.fancybox__slide {
  gap: 2rem;
  padding: 4rem 4rem 2rem 4rem;
  &::before, &::after { @extend %hidden; }
}

.fancybox__content { flex: 1; }
.fancybox__caption {
  @extend figcaption;
  color: color('neutral', 100);
}
