.tags {
  font-size: 0;
  list-style-type: none;
  margin: 0;
  margin-bottom: -.5rem;
  padding: 0;
  .tag {
    background-color: color('neutral', 50);
    border-radius: .25rem;
    color: color('neutral', 400);
    display: inline-block;
    font-size: .833rem;
    line-height: 1rem;
    font-weight: 600;
    margin: 0 .25rem .25rem 0;
    padding: .25rem .5rem;
    @include dark-mode {
      background-color: color('neutral', 700);
      color: color('neutral', 900);
    }
    &.credit {
      background: none;
      box-shadow: inset 0 0 0 1px color('neutral', 50);
      color: color('neutral', 200);
      @include dark-mode {
        box-shadow: inset 0 0 0 1px color('neutral', 800);
        color: color('neutral', 800);
      }
    }
  }
  @include breakpoint($breakpoint-desktop) {
    .tag {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.425rem;
    }
  }
}

.tags-stylized {
  @extend .tags;
  display: flex;
  margin: 0 -.5rem;
  .tag {
    background: none;
    border: 2px dashed fade_out(color('brand'), .8);
    color: color('brand');
    flex: 0 1 percentage(calc(1/3));
    margin: 0 .5rem;
    padding: .5rem 1rem;
    text-align: center;
  }
  @include breakpoint($breakpoint-tablet) {
    margin-bottom: 2rem;
    margin-top: 2rem;
    .tag {
      font-family: $custom-font-regular;
      font-size: 1.2rem;
      line-height: 1.575rem;
      padding: 1rem;
    }
  }

  @include breakpoint($breakpoint-desktop) {
    .tag {
      font-size: 1.44rem;
      line-height: 1.875rem;
    }
  }

  @include breakpoint($breakpoint-widescreen) {
    .tag { padding: 1.5rem; }
  }
}