.card {
  .card-action-area {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: block;
    position: relative;
    text-decoration: none;
    @include dark-mode { background-color: color('neutral', 800); }
  }
  .card-image {
    background: color('neutral', 50);
    height: 100%;
    position: relative;
    width: 100%;
    img {
      display: block;
      height: 100%;
      max-height: 100vmin;
      object-fit: cover;
      object-position: 0 0;
      width: 100%;
    }
    &::after {
      background: color('neutral', 900);
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      mix-blend-mode: difference;
      opacity: .5;
      position: absolute;
      width: 100%;
    }
  }
  .card-content {
    padding: 1.5rem;
    position: relative;
    h3 {
      font-size: 1.44rem;
      line-height: 1;
      @include dark-mode { color: color('neutral', 200); }
    }
    .fab {
      position: absolute;
      right: 1rem;
      top: 0;
      transform: translateY(-50%);
    }
  }
  @include breakpoint($breakpoint-tablet) {
    .card-image {
      img {
        max-height: initial;
        min-height: initial;
      }
      &::after { content: none; }
    }
    .card-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      text-align: center;
      transition: opacity .2s ease-in-out;
      width: 100%;
      h3 {
        color: #fff;
        font-family: $custom-font-regular;
        text-transform: uppercase;
      }
      .fab { @extend %hidden-tablet; }
    }
  }
}
