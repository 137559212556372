.button {
  background: color('neutral', 50);
  color: color('neutral', 900);
  cursor: pointer;
  display: inline-block;
  font-size: .833rem;
  letter-spacing: .1rem;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  transition: background .2s;
  .button-icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    speak: none;
    transform: translateY(-100%);
    width: 100%;
    .icon {
      height: 2rem;
      width: 2rem;
    }
  }
  .button-content {
    display: inline-block;
    padding: 1rem 2rem;
  }
  &:active { background: color('neutral', 100); }

  @include breakpoint($breakpoint-tablet) {
    font-size: 1rem;
    .button-content {
      padding: 1.5rem 2rem;
    }
  }

  @include breakpoint($breakpoint-desktop) {
    .button-icon {
      backface-visibility: hidden;
      transition: transform .2s;
    }
    .button-content {
      backface-visibility: hidden;
      padding: 2rem 3rem;
      transition: transform .2s;
    }
    &:hover {
      .button-icon { transform: translateY(0); }
      .button-content { transform: translateY(100%); }
    }
  }
}