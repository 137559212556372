.nav-site {
  .wrapper {
    @include layout-constraints;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .monogram {
    margin-left: -1rem;
    img {
      display: block;
      height: 3rem;
      pointer-events: none;
      width: auto;
    }
    h1 { @extend %hidden; }
  }
  .menu {
    display: flex;
    list-style-type: none;
    margin: 0 -1rem 0 0;
    padding: 0;
    a {
      color: color('neutral', 300);
      font-size: .833rem;
      font-weight: 700;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      @include dark-mode { color: color('neutral', 600); }
      &::after {
        background: fade_out(color('brand'), .8);
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
      }
      &:hover:not(.selected) {
        color: color('neutral', 700);
        @include dark-mode { color: color('neutral', 200); }
      }
      &.selected {
        color: color('brand');
        pointer-events: none;
      }
    }
  }
  a {
    display: block;
    padding: 1rem;
  }
  @include breakpoint($breakpoint-tablet) {
    .monogram {
      img { height: 4rem; }
    }
    .menu {
      a {
        &::after { height: 4px; }
      }
    }
  }
  @include breakpoint($breakpoint-desktop) {
    .menu {
      a {
        font-size: 1rem;
        &:hover:not(.selected) {
          &::after {
            opacity: 1;
            transform: translateY(2px);
          }
        }
        &::after {
          transition-duration: .2s;
          transition-property: opacity, transform;
          transform: translateY(6px);
        }
      }
    }
  }
}
