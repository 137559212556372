.onehub {
  .ribbon {
    @include project-background('onehub');
    h2 {
      @extend .inverted;
      top: calc(100% / 1.5);
    }
  }
  .website {
    .hero {
      figcaption { margin-top: 0; }
    }
  }
}
