.about {
  .introduction {
    position: relative;
    .hello {
      margin-top: 1rem;
      .avatar {
        border-radius: 50%;
        display: block;
        height: 4rem;
        width: auto;
      }
      .wave { @extend %hidden; }
    }
  }
  section {
    h4 { margin-bottom: 2rem; }
  }

  @include breakpoint($breakpoint-tablet) {
    .introduction {
      .hello { margin-top: 2rem; }
    }
  }

  @include breakpoint($breakpoint-desktop) {
    .introduction {
      .hello {
        bottom: 0;
        margin: 0;
        position: absolute;
        transform: translateX(-3rem) translateY(10rem);
        .avatar { height: 6rem; }
      }
    }
    .split-personality {
      display: flex;
      @include layout-constraints($quarter);
      position: relative;
      section {
        margin-bottom: 0;
        .content { width: 100%; }
        h4 {
          padding-top: 0;
          &::before { @extend %hidden-desktop; }
        }
      }
      section:first-child { margin-right: 3rem; }
      section:last-child { margin-left: 3rem; }
      &::before, &::after {
        background: repeating-linear-gradient(-45deg, transparent, transparent 4px, color('neutral', 50) 2px, color('neutral', 50) 6px);
        content: '';
        display: block;
        height: 1rem;
        position: absolute;
        width: 100%;
        @include dark-mode { background: repeating-linear-gradient(-45deg, transparent, transparent 4px, color('neutral', 800) 2px, color('neutral', 800) 6px); }
      }
      &::before { top: 0; }
      &::after { bottom: 0; }
    }
    .site {
      h4 {
       padding-top: 0;
       &::before { @extend %hidden-desktop; }
      }
    }
  }
}
