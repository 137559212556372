.icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  text-indent: -9999px;
  height: 2rem;
  width: 2rem;

  @include breakpoint($breakpoint-tablet) {
    height: 3rem;
    width: 3rem;
  }
}

.icon-small {
  height: 1.25rem;
  width: 1.25rem;
}

.icon-app-store { background-image: url('data-url:/src/images/icons/app-store.svg'); }
.icon-chevron-right { background-image: url('data-url:/src/images/icons/chevron-right.svg'); }
.icon-download { background-image: url('data-url:/src/images/icons/download.svg'); }
.icon-email { background-image: url('data-url:/src/images/icons/email.svg'); }
.icon-figma { background-image: url('data-url:/src/images/icons/figma.svg'); }
.icon-globe { background-image: url('data-url:/src/images/icons/globe.svg'); }
