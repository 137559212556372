footer {
  .wrapper {
    background-color: color('neutral', 50);
    @include dark-mode { background-color: color('neutral', 800); }
  }
  .elsewhere-list {
    @include layout-constraints;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0 auto !important;
    padding: 2rem 1rem;
    li:last-child { @extend %hidden; }
    a {
      display: block;
      opacity: .48;
      padding: 1rem;
      @include dark-mode { opacity: .56; }
      &:hover { opacity: 1; }
      img {
        display: block;
        height: 2rem;
        pointer-events: none;
        width: 2rem;
      }
    }
  }
  .copyright {
    color: color('neutral', 200);
    font-weight: 400;
    margin: 1rem 0;
    text-align: center;
    @include dark-mode { color: color('neutral', 700); }
  }

  @include breakpoint($breakpoint-tablet) {
    .elsewhere-list {
      padding: 3rem 0;
      li:last-child { @extend %shown-tablet; }
      a {
        img {
          height: 3rem;
          width: 3rem;
        }
      }
    }
  }

  @include breakpoint($breakpoint-desktop) {
    .elsewhere-list {
      padding: 4rem 0;
      a {
        transition: transform .2s;
        &:hover { transform: translateY(-.25rem); }
      }
    }
    .copyright {
      font-size: 1.2rem;
      margin: 2rem 0;
    }
  }

  @include breakpoint($breakpoint-widescreen) {
    .elsewhere-list { padding: 5rem 0; }
  }
}
