.fab {
  align-items: center;
  background-color: color('brand');
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
              0px 6px 10px 0px rgba(0, 0, 0, 0.14),
              0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  display: flex;
  height: 3rem;
  font-size: .833rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: .1rem;
  text-transform: uppercase;
  width: 3rem;

  @include breakpoint($breakpoint-tablet) {
    height: 4rem;
    width: 4rem;
  }
}