// Shared placeholder selectors used throughout the site and included at the top of the payload.
//
// No styleguide reference.

%hidden { display: none; }

@include breakpoint($breakpoint-tablet) {
  %hidden-tablet { display: none; }
  %shown-tablet { display: initial; }
}

@include breakpoint($breakpoint-desktop) {
  %hidden-desktop { display: none; }
}
