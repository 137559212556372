.introduction {
  .content { @extend %content-quarter; }
}

section {
  .content {
    @extend %content-half;
    a {
      border-bottom: 2px solid color('neutral', 100);
      color: color('neutral', 900);
      @include dark-mode {
        border-color: color('neutral', 700);
        color: color('neutral', 100);
      }
      &:hover {
        border-color: color('brand');
        color: color('brand');
      }
    }
  }
}

.cta {
  @extend %content;
  text-align: center;
}

.hero {
  @extend %content-quarter;
  img {
    display: block;
    max-width: 100%;
  }
  figcaption {
    border-top: 1px solid color('neutral', 100);
    margin-top: 1rem;
    @include dark-mode { border-color: color('neutral', 800); }
  }
}

.sidekick {
  margin: 1rem 0;
  .frame {
    position: relative;
    &::after {
      border: 1px solid color('neutral', 100);
      bottom: 0;
      content: '';
      left: 0;
      mix-blend-mode: multiply;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  img {
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

@include dark-mode {
  img, object { filter: brightness(.8) contrast(1.2); }
}

@include breakpoint($breakpoint-tablet) {
  .sidekick {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

@include breakpoint($breakpoint-desktop) {
  .hero {
    margin-bottom: 4rem;
    margin-top: 4rem;
    figcaption { margin-top: 2rem; }
  }

  .sidekick {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
